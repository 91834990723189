import React from 'react';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import styles from './tag-list.module.css';

const TagList = ({ tags }) => (
  <ul className={styles.tag__list}>
    {tags.map(tag => (
      <li key={tag} style={{ marginBottom: 0 }}>
        <Link className={styles.tag} to={`/tags/${kebabCase(tag)}`}>
          {tag}
        </Link>
      </li>
    ))}
  </ul>
);

export default TagList;
