import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import { BlogIndex } from '../components/blog-index';

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext;
  const { site } = data;
  const { edges } = data.allMdx;

  return (
    <Layout location={location} title={site.siteMetadata.title}>
      <div>
        <h1>Post(s) tagged with "{tag}"</h1>
        {edges.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <BlogIndex
              key={node.fields.slug}
              slug={node.fields.slug}
              title={title}
              date={node.frontmatter.date}
              readingTime={node.fields.readingTime}
              description={node.frontmatter.description || node.excerpt}
              tags={node.frontmatter.tags}
            />
          );
        })}
        <nav style={{ marginTop: `2rem` }}>
          <Link to="/tags">View all tags</Link>
        </nav>
      </div>
    </Layout>
  );
};

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired
  }),
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: {
        title: PropTypes.string,
        author: PropTypes.string,
        siteUrl: PropTypes.string
      }
    }),
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired
            })
          })
        }).isRequired
      )
    })
  })
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            readingTime {
              minutes
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            tags
          }
        }
      }
    }
  }
`;
