import React, { useMemo } from 'react';

const readingEmojis = [
  '🍵',
  '👓',
  '📖',
  '👨🏻‍💻',
  '👩🏻‍💻',
  '👨🏻‍🏫',
  '👩🏻‍🏫',
  '🧑🏻‍🏫',
  '📓',
  '⏳'
];

const useReadingEmoji = () => {
  return useMemo(() => {
    const index = Math.floor(Math.random() * readingEmojis.length);
    return readingEmojis[index];
  }, []);
};

export default ({ minutes, tag: Tag = 'small' }) => {
  const emoji = useReadingEmoji();
  return (
    <React.Fragment>
      <span rol="img">{emoji}</span> <Tag>{Math.ceil(minutes)} min</Tag>
    </React.Fragment>
  );
};
