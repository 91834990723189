import React from 'react';
import { Link } from 'gatsby';
import ReadingTime from '../reading-time';

import { rhythm } from '../../utils/typography';
// import BlogIndexImg from './blog-index-img';

import TagList from '../tag-list/tag-list';

const BlogIndex = ({
  slug,
  title,
  readingTime,
  date,
  description,
  tags = []
}) => (
  <div>
    <h3
      style={{
        marginBottom: rhythm(1 / 4)
      }}
    >
      <Link style={{ boxShadow: `none` }} to={slug}>
        {title}
      </Link>
    </h3>
    <small>{date}</small>
    <strong>{` ${String.fromCharCode(8729)} `}</strong>
    <ReadingTime minutes={readingTime.minutes} />
    <p
      style={{ marginBottom: `3px` }}
      dangerouslySetInnerHTML={{
        __html: description
      }}
    />
    {tags && <TagList tags={tags} />}
  </div>
);

export default BlogIndex;
